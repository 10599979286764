const COLORS = {
  ashgrey: 'BAC0D6',
  charcoal: '3C4144',
  gold: 'F8AF0A',
  irishgreen: '1E3D01',
  navy: '00021A',
  purple: '29074D',
  white: 'FFFFFF',
  black: '000000',
  darkheather: '',
  heathergrey: '',
  lightblue: '9AB9D4',
  navyheather: '',
  red: 'A90519',
  carolinablue: '5E82C7',
  forest: '112509',
  heliconia: 'A7073E',
  lightpink: 'EFB9C9',
  orange: 'E53F04',
  royal: '1F448C',
  brown: '332822',
  icegrey: 'E2E2E2',
  azalea: 'E46DA6',
  drakred: '3C050D',
  forestgreen: '112509',
  kellygreen: '23733F',
  mitarygreen: '4D4D37',
  smokegrey: '565758',
  natural: 'E3D9C3',
  safetypink: 'F33F72',
};

/* eslint-disable */
export const getColorNameByValue = (value) => {
  return Object.keys(COLORS).find((key) => COLORS[key] === value);
};
