/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page"> Wishlist</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Wish list</li>
          </ol>
        </div>
      </nav>
      <div class="cart cart-checkout wish-list">
        <div class="container">
          <p v-if="!productItems.length">You currently have no favorites.</p>
          <div class="row">
            <div v-for="product in productItems" :key="product.id" class="col-lg-4 col-md-6">
              <router-link :to="{ name: 'EditDesign', params: { id: product.id } }">
                <div class="cart__items">
                  <img :src="product.thumbnail" class="cart__items--image" alt="" />
                  <div class="cart__items--content w-100">
                    <div class="title d-flex justify-content-between">
                      <h3 class="mb-0 text-ellipse-1">{{product.productName}}</h3>
                      <button class="btn p-0" @click.stop.prevent="deleteWishlist(product.id)">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="3"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(45 4 3)"
                            fill="#656782"
                          />
                          <rect
                            x="2.93945"
                            y="20.6777"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(-45 2.93945 20.6777)"
                            fill="#656782"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="list-item">{{ product.productDescription }}</p>
                    <p class="list-item product-color">Color: {{ colorName(product.productColor) }}</p>
                    <p class="list-item">Size: {{ product.productSize.toUpperCase() }}</p>
                    <p class="list-item price">${{ getPrize(product) }}</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { getColorNameByValue } from '@/helpers/color.helper';

export default {
  data() {
    return {
      productItems: [],
    };
  },
  computed: {
    ...mapGetters({
      wishlists: 'wishlist/wishlists',
      masterTypesList: 'masterTypes/masterTypeList',
    }),
  },
  watch: {
    wishlists(newWishlist) {
      this.productItems = newWishlist.map((wishlist) => wishlist.productDesign);
    },
  },
  methods: {
    ...mapActions({
      getWishlists: 'wishlist/getWishlists',
      removeWishlist: 'wishlist/removeWishlist',
      getMasterTypes: 'masterTypes/getMasterTypes',
    }),
    colorName(value) {
      return getColorNameByValue(value);
    },
    deleteWishlist(id) {
      this.removeWishlist({ productId: id });
    },
    getPrize(product) {
      const size = product.productSize.toLowerCase();
      const sizePrices = product.masterProductType.meta['size-price'];
      if (!sizePrices) { return 10; }
      return sizePrices.filter((item) => item.size.toLowerCase() === size)[0].price;
    },
  },
  created() {
  },
  mounted() {
    this.getWishlists().then(() => {
      this.productItems = this.wishlists.map((wishlist) => wishlist.productDesign);
    });
  },
};
</script>

<style scoped>
.product-color {
  text-transform: capitalize;
}
</style>
